.navbar {
    display: flex;
    align-items: center;
    background-color: #333;
    padding: 10px 20px;
}

.navbar__logo {
    color: #fff;
    font-size: 24px;
    margin-right: auto;
}

.navbar__button {
    color: #fff;
    padding: 10px 15px;
    margin: 0 10px;
    cursor: pointer;
    position: relative;
}

.navbar__button:hover {
    background-color: #444;
    border-radius: 4px;
}

.dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: rgba(50,50,50,0.9);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 10px 0;
    z-index: 10;
    min-width: 150px;
}

.dropdown ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.dropdown ul li {
    padding: 10px 15px;
    color: rgb(255,255,255);
    cursor: pointer;
}

.dropdown ul li:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.nav_a {
    text-decoration: none;
}