/* Global Styles */

/* Container Styles */
.auth-container {
    width: 100%;
    max-width: 400px;
    padding: 20px;
}

.auth-form {
    background-color: #1e1e1e;
    padding: 20px 40px;
    border-radius: 8px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.6);
    width: 100%;
}

/* Title Styles */
.auth-title {
    color: #ffffff;
    text-align: center;
    margin-bottom: 20px;
}

/* Input Fields */
.auth-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #333;
    border-radius: 4px;
    background-color: #2a2a2a;
    color: #ffffff;
    box-sizing: border-box;
}

.auth-input::placeholder {
    color: #888888;
}

/* Button Styles */
.auth-button {
    width: 100%;
    padding: 10px;
    background-color: #6200ea;
    border: none;
    border-radius: 4px;
    color: #ffffff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.auth-button:hover {
    background-color: #3700b3;
}

/* Message Display */
.auth-message {
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
    color: #ff6e40;
}

/* Focus States */
.auth-input:focus {
    border-color: #6200ea;
    outline: none;
}

.login__container {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    height: 100vh;
}
.login__container-inner {
    margin: auto;
    align-items: center;
}